'use client'
import Image from 'next/image'
import styles from './cornerstone.module.css'

export default function CornerStone() {
  return (
    <div className={styles.cornerStoneWrapper}>
      <div className={styles.cornerStone}>
        <Image src="/images/cornerstone-cutout.png" alt="Cornerstone" fill={true} sizes="{max-width: 768px} 100vw, (max-width: 1200px) 50vw, 33vw" />
      </div>
    </div>
  )
}
