'use client'
import { Link } from '@mui/material'
import styles from './wedge.module.css'

export default function WedgeBanner() {
  return (
    <div className={styles.wedgeBanner}>
      <div className={styles.logo}>
        <Link href="/">
          <img
            src="/shift_logo_personas_single_white.svg"
            alt="Shift AI Personas logo"
            width="100%"
            height="100%"
          />
        </Link>
      </div>
    </div>
  )
}
